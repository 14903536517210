import { useMenu } from '@/providers/menu';
import {
	Add as AddIcon,
	Delete as DeleteIcon,
	Edit as EditIcon,
	ExpandLess as ExpandLessIcon,
	ExpandMore as ExpandMoreIcon,
	Menu as MenuIcon,
} from '@mui/icons-material';
import {
	Button,
	Collapse,
	Grid,
	IconButton,
	MenuItem,
	Pagination,
	Stack,
	Table,
	TableBody,
	TableBodyProps,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableRowProps,
	Typography,
} from '@mui/material';
import { isEqual, pick } from 'lodash-es';
import React, { forwardRef, Fragment, memo, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../loading';
import PageSection from '../page/section';
import Sortable from '../sortable';
import { pageSizeOptions } from './enhancedList';
import type { EnhancedDisplayProps, EnhancedTableProps } from './helpers';
import { _deleteRow } from './helpers';

function ExpandRow( {
	renderPanel,
	children,
	...props
}: {
	renderPanel: ReactNode,
	children: ( open: boolean ) => ReactNode
} & Omit<TableRowProps, 'children'> ) {
	const [ open, setOpen ] = useState( false );
	
	return (
		<Fragment>
			<TableRow
				{...props}
				sx={{ cursor: Boolean( renderPanel ) ? 'pointer' : 'default' }}
				onClick={Boolean( renderPanel ) || props.onClick
					? ( e ) => {
						setOpen( ( open ) => !open );
						props.onClick?.( e );
					} : undefined}>
				{children( open )}
			</TableRow>
			{Boolean( renderPanel ) && (
				<TableRow>
					<TableCell
						style={{ paddingBottom: 0, paddingTop: 0, border: 0 }}
						colSpan={100}>
						<Collapse unmountOnExit in={open} timeout='auto'>
							{renderPanel}
						</Collapse>
					</TableCell>
				</TableRow>
			)}
		</Fragment>
	);
}

function EnhancedTable<Item>( {
	title,
	pageSectionProps,
	data = [],
	extraData,
	setData,
	dataCount,
	pageNumber,
	setPageNumber,
	pageActualSize,
	setPageActualSize,
	editable,
	sortable,
	selectable,
	onClick,
	loading,
	loadingComponent = <Loading/>,
	emptyComponent = (
		<Typography textAlign='center' color='text.secondary' py={1}>
			Nothing to display
		</Typography>
	),
	headers,
	columns,
	footer,
	hover = true,
	hideAddIcon,
	hideDelete,
	cellProps,
	renderPanel,
	tableContainerProps,
	...props
}: EnhancedDisplayProps<Item> & EnhancedTableProps<Item> ) {
	const { showMenu } = useMenu();
	const { t } = useTranslation();
	const [ pageIndex, setPageIndex ] = useState( pageNumber || 0 );
	const [ pageSize, setPageSize ] = useState( pageActualSize || 10 ); // Default page size, you can adjust or derive from props
	const [ isAllSelected, setIsAllSelected ] = useState( props.defaultPageSize === 'All' );
	const pageCount = Math.ceil( ( dataCount || data.length ) / pageSize );
	const validPageCount = !isNaN( pageCount ) ? pageCount : 1;
	
	const paginatedData = useMemo( () => {
		if ( !props.hasPagination ) return data;
		const startIndex = pageIndex * pageSize;
		const endIndex = startIndex + pageSize;
		return data.slice( startIndex, endIndex );
	}, [ data, pageIndex, pageSize ] );
	
	const dataItems = useMemo( () => {
		const row = ( { item, index, handle }: { item, index, handle? } ) => (
			<ExpandRow
				hover={hover}
				renderPanel={renderPanel?.( item, index )}
				onClick={selectable || onClick ? () => onClick?.( item, index ) : undefined}>
				{( open ) => (
					<Fragment>
						{renderPanel && (
							<TableCell>{open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}</TableCell>
						)}
						{sortable && (
							<TableCell>
								<MenuIcon {...handle}/>
							</TableCell>
						)}
						{columns( item, index ).map( ( cell, index ) => (
							<TableCell key={index} {...cellProps?.[ index ]}>
								{cell}
							</TableCell>
						) )}
						{Boolean( editable ) && (
							<TableCell>
								<Stack direction='row' spacing={1}>
									{editable?.onEdit && (
										<IconButton
											color='primary'
											onClick={( e ) => {
												e.stopPropagation();
												editable?.onEdit?.( item, index );
											}}>
											<EditIcon/>
										</IconButton>
									)}
									{!hideDelete?.( item ) && (
										<IconButton
											color='error'
											onClick={( e ) => {
												e.stopPropagation();
												_deleteRow(
													data,
													setData,
													editable,
													selectable,
													item,
													index,
													undefined,
													undefined,
												);
											}}>
											<DeleteIcon/>
										</IconButton>
									)}
								</Stack>
							</TableCell>
						)}
					</Fragment>
				)}
			</ExpandRow>
		);
		
		const Body: any = forwardRef<any, TableBodyProps>( ( { children, ...props }, ref ) => (
			<TableBody {...props} ref={ref}>
				{children}
				{footer}
			</TableBody>
		) );
		
		return sortable ? (
			<Sortable
				items={paginatedData as any}
				setItems={setData as any}
				renderItem={row}
				tag={Body}
			/>
		) : (
			<TableBody>
				{paginatedData.map( ( item, index ) => (
					<Fragment key={index}>{row( { item, index } )}</Fragment>
				) )}
				{footer}
			</TableBody>
		);
	}, [ paginatedData, extraData, columns, Boolean( editable ), sortable ] );
	
	return (
		<PageSection primary={title} {...pageSectionProps}>
			<TableContainer
				{...tableContainerProps}
				sx={{
					'border'      : 1,
					'borderRadius': 1,
					'borderColor' : 'divider',
					'overflow'    : 'auto',
					'.minWidth'   : { width: '0.001%' },
					...tableContainerProps?.sx,
				}}>
				<Table
					size='small'
					{...props}
					sx={{
						'.MuiTableCell-head'                  : { border: 0, py: .5 },
						'.MuiTableBody-root .MuiTableRow-root': {
							'transition'  : '.3s',
							':hover'      : selectable ? { cursor: 'pointer' } : undefined,
							'borderBottom': 0,
							'borderColor' : 'divider',
						},
						'.MuiTableCell-root': {
							whiteSpace     : 'nowrap',
							overflow       : 'hidden',
							alignItems     : 'center',
							textOverflow   : 'ellipsis',
							WebkitLineClamp: 1,
							borderTop      : 1,
							borderBottom   : 0,
							borderTopColor : 'divider',
						},
						...props?.sx,
					}}>
					<TableHead sx={{ bgcolor: 'action.focus' }}>
						<TableRow>
							{renderPanel && <TableCell className='minWidth'/>}
							{sortable && <TableCell className='minWidth'/>}
							{headers.map( ( cell, index ) => (
								<TableCell key={index} {...cellProps?.[ index ]}>
									{cell}
								</TableCell>
							) )}
							{Boolean( editable ) && (
								<TableCell className='minWidth'>
									{!loading && !hideAddIcon && (
										<IconButton
											onClick={async () => {
												editable.onAdd?.();
												editable.newData && setData?.( [
													...paginatedData,
													{ ...await editable.newData() },
												] );
											}}>
											<AddIcon/>
										</IconButton>
									)}
								</TableCell>
							)}
						</TableRow>
					</TableHead>
					{loading || !paginatedData.length ? (
						<TableBody>
							<TableRow>
								<TableCell colSpan={headers.length + 2}>
									{loading ? loadingComponent : emptyComponent}
								</TableCell>
							</TableRow>
						</TableBody>
					) : dataItems}
				</Table>
			</TableContainer>
			{props.hasPagination && data.length >= 10 && (
				<Grid container spacing={1} mt={0.5}>
					<Grid item xs={12} sm={6}>
						<Stack direction='row' alignItems='center'>
							<Typography>{t( 'common:rows-per-page' )}:</Typography>
							<Button
								component='li'
								variant='text'
								sx={{
									'color'       : 'text.primary',
									'fontWeight'  : 'normal',
									'minWidth'    : 32,
									'height'      : 32,
									'borderRadius': '16px',
									'ml'          : 1,
									'padding'     : '0 6px',
									'bgcolor'     : 'divider',
									':hover'      : {
										filter : 'brightness(80%)',
										bgcolor: 'divider',
									},
								}}
								onClick={( e ) => showMenu( ( { closeMenu } ) => (
									<Fragment>
										{pageSizeOptions.map( ( count, index ) => (
											<MenuItem
												key={index}
												selected={count === ( isAllSelected ? 'All' : pageSize )}
												onClick={() => {
													if ( count === 'All' ) {
														setIsAllSelected( true );
														setPageSize( data.length );
														setPageActualSize?.( data.length );
													} else {
														setIsAllSelected( false );
														setPageSize( Number( count ) );
														setPageActualSize?.( Number( count ) );
													}
													setPageIndex( 0 );
													closeMenu();
												}}>
												{count}
											</MenuItem>
										) )}
										{Boolean( props.savePageSize ) && (
											<MenuItem
												onClick={() => {
													props.savePageSize?.( isAllSelected ? 'All' : Number( pageSize ) );
													closeMenu();
												}}>
												Save page size
											</MenuItem>
										)}
									</Fragment>
								), e.currentTarget, {
									anchorOrigin   : { vertical: 'bottom', horizontal: 'center' },
									transformOrigin: { vertical: 'top', horizontal: 'center' },
								} )}>
								{pageSize}
							</Button>
						</Stack>
					</Grid>
					<Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'end' } }}>
						<Pagination
							page={pageIndex + 1}
							count={validPageCount}
							onChange={( event, value ) => {
								setPageIndex( value - 1 );
								setPageNumber?.( value - 1 );
							}}
						/>
					</Grid>
				</Grid>
			)}
		</PageSection>
	);
}

export default memo( EnhancedTable, ( prevProps, nextProps ) =>
	isEqual( pick( prevProps, [ 'title', 'loading' ] ), pick( nextProps, [ 'title', 'loading' ] ) )
	&& Object.is( prevProps.data, nextProps.data )
	&& Object.is( prevProps.extraData, nextProps.extraData ) ) as typeof EnhancedTable;
